import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import materialRoutes from 'app/views/material-kit/MaterialRoutes';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const ExamNotFound = Loadable(lazy(() => import('app/views/sessions/ExamNotFound')));

// echart page

// dashboard page

const routes = [
  {
    element: <MatxLayout />,
    children: [
      ...materialRoutes
      // dashboard route

      // e-chart rooute
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/examnotfound/404', element: <ExamNotFound /> },
  { path: '/', element: <Navigate to="/candidatetest" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
