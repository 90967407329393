// Create a context to hold the score
import React, { createContext, useContext, useState } from 'react';

const ScoreContext = createContext();

export const ScoreProvider = ({ children }) => {
  const [score, setScore] = useState(null);

  return <ScoreContext.Provider value={{ score, setScore }}>{children}</ScoreContext.Provider>;
};

export const useScore = () => {
  return useContext(ScoreContext);
};
