import { lazy } from 'react';
import Loadable from 'app/components/Loadable';
const CandidateTest = Loadable(lazy(() => import('./candidate/CandidateTest')));
const AppIcon = Loadable(lazy(() => import('./icons/AppIcon')));
const Successful = Loadable(lazy(() => import('./candidate/SuccessfulSubmissionPage')));

const materialRoutes = [
  {
    path: '/candidatetest',
    element: <CandidateTest />
  },

  {
    path: '/successful',
    element: <Successful />
  },

  {
    path: '/material/icons',
    element: <AppIcon />
  }
];

export default materialRoutes;
